<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bold"
          >
            <b-input
              id="filter_search"
              v-model="tableProductCategories.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Product
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tableProductCategories"
        hover
        responsive
        class="mt-2"
        :per-page="tableProductCategories.perPage"
        :current-page="tableProductCategories.currentPage"
        :items="myProvider"
        :fields="tableProductCategories.fields"
        :sort-by.sync="tableProductCategories.sortBy"
        :sort-desc.sync="tableProductCategories.sortDesc"
        :sort-direction="tableProductCategories.sortDirection"
        :filter="tableProductCategories.filter"
        :filter-included-fields="tableProductCategories.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableProductCategories.currentPage * tableProductCategories.perPage - tableProductCategories.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(action)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableProductCategories.perPage"
              :options="tableProductCategories.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableProductCategories.currentPage"
            :total-rows="tableProductCategories.totalRows"
            :per-page="tableProductCategories.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-product"
      scrollable
      no-close-on-backdrop
      :title="ModalTitle"
      @ok="onValidateProduct"
    >
      <ValidationObserver
        ref="formProduct"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >

          <ValidationProvider
            #default="{ errors }"
            name="category"
            vid="category"
            rules="required"
          >
            <b-form-group
              label="Category"
              label-for="category"
              label-class="font-weight-bold"
            >
              <b-form-select
                id="category"
                v-model="product.category"
                :options="list.productCategories"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- select category --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="product code"
            vid="product_code"
            rules="required|max:50"
          >
            <b-form-group
              label="Product Code"
              label-for="product_code"
              label-class="font-weight-bold"
            >
              <b-input
                id="product_code"
                v-model="product.product_code"
                type="text"
                placeholder="enter product code"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy || state.editing"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="category name"
            vid="product_name"
            rules="required|max:200"
          >
            <b-form-group
              label="Product Name"
              label-for="product_name"
              label-class="font-weight-bold"
            >
              <b-input
                id="product_name"
                v-model="product.product_name"
                type="text"
                placeholder="enter category name"
                autocomplete="off"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="product.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="state.busy"
              switch
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminProductService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminProducts',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Products'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      list: {
        productCategories: []
      },
      product: {
        id: null,
        product_code: null,
        product_name: null,
        category: null,
        active: 0
      },
      tableProductCategories: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'action', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'product_code' },
          { key: 'product_name' },
          { key: 'active', label: 'IS ACTIVE?', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    ModalTitle () {
      return this.state.editing ? 'Edit Product' : 'Add Product'
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async myProvider (ctx) {
      return await AdminProductService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter
        })
      ).then(({ data }) => {
        this.tableProductCategories.totalRows = data.total_rows
        return data.items
      }).catch(() => [])
    },

    onTableRefresh () {
      this.$refs.tableProductCategories.refresh()
    },

    async getProductCategories () {
      this.state.busy = true
      await SharedListService.getProductCategories().then(({ data }) => {
        this.list.productCategories = data.map(({ id, category_name }) => ({
          text: category_name,
          value: id
        }))
        this.state.busy = false
      })
    },

    onShowAddForm () {
      this.state.editing = false
      this.product.id = null
      this.product.product_code = null
      this.product.product_name = null
      this.product.category = null
      this.product.active = 1
      this.getProductCategories().then(() => {
        this.$bvModal.show('modal-product')
      })
    },

    onShowEditForm (product) {
      this.state.editing = true
      this.product.id = product.id
      this.product.product_code = product.product_code
      this.product.product_name = product.product_name
      this.product.active = product.active

      this.getProductCategories().then(() => {
        this.product.category = product.category_id
        this.$bvModal.show('modal-product')
      })
    },

    async onValidateProduct (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formProduct.validate().then(async isAllFieldsValid => {
        if (isAllFieldsValid) {
          let text = 'Do you really want to create product?'
          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutProduct()
              }

              return this.onPostProduct()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onPostProduct () {
      return new Promise(resolve => {
        AdminProductService.post(this.product).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-product')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.onTableRefresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formProduct.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onPutProduct () {
      return new Promise(resolve => {
        AdminProductService.put(this.product).then(({ data }) => {
          this.$bvModal.hide('modal-product')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableProductCategories.localItems, { id: data.product.id })
            row.created_at = data.product.created_at
            row.product_code = data.product.product_code
            row.product_name = data.product.product_name
            row.category_id = data.product.category_id
            row.active = data.product.active
            row.updated_at = data.product.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formProduct.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }
}
</script>
